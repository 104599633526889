import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import spriteSVG from "../sprite/sprite-build.svg"

export default function BlogPost(props) {
  const data = props.pageContext

  return (
    <Layout>
      <Helmet>
        <body className="single-post"></body>
      </Helmet>
      <SEO title={data.title} description={data.excerpt} />

      <section className="c-section c-section--pagehead">

        <div className="o-container o-v-middle">
          <div className="o-flex-wrap">
            <h1 className="o-type-6">Blog</h1>
            <a href="https://e4ufd97gowu.typeform.com/to/lJPnKDSr" className="c-button">Join the Wait List</a>
          </div>

        </div>

      </section>

      <section className="c-section c-section--gradient">

        <div className="o-container">
          
          <div className="c-post-detail">
            <h1 className="o-type-6 u-mb-x4" dangerouslySetInnerHTML={{ __html: data.title }}></h1>

            <figure className="u-mb-x4 u-mb-x8@md"><img src={data.featured_media?.publicURL} alt="" /></figure>

            <div className="c-blog-post__excerpt">
              <div dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>

          </div>
          

        </div>

      </section>

    </Layout>
  )
}
